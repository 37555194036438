import { createContext, useContext, useRef, useState } from 'react'
import { useFrame } from '@react-three/fiber'
import { useBox, useConeTwistConstraint } from '@react-three/cannon'
import { createRagdoll } from '../helpers/createRagdoll'
import { useDragConstraint } from '../helpers/Drag'
import { Block } from '../helpers/Block'
import { Html } from '@react-three/drei'
import * as THREE from 'three'

const { shapes, joints } = createRagdoll(5.5, Math.PI / 16, Math.PI / 16, 0)
const context = createContext()

const BodyPart = ({ config, children, render, name, ...props }) => {
  const { color, args, mass, position } = shapes[name]
  const parent = useContext(context)
  const [ref] = useBox(() => ({ mass, args, position, linearDamping: 0.99, ...props }))
  useConeTwistConstraint(ref, parent, config)
  const bind = useDragConstraint(ref)
  return (
    <context.Provider value={ref}>
      <Block castShadow receiveShadow ref={ref} {...props} {...bind} scale={args} name={name} color={color}>
        {render}
      </Block>
      {children}
    </context.Provider>
  )
}

function Face() {
  const [isOccluded, setOccluded] = useState()
  const [isInRange, setInRange] = useState()
  const isVisible = isInRange && !isOccluded
  const mouth = useRef()
  const eyes = useRef()
  const vec = new THREE.Vector3()
  useFrame((state) => {
    eyes.current.position.y = Math.sin(state.clock.elapsedTime * 2) * 0.1
    mouth.current.scale.y = (1 + Math.sin(state.clock.elapsedTime * 2)) * 0.6
    const range = state.camera.position.distanceTo(mouth.current.getWorldPosition(vec)) <= 100
    if (range !== isInRange) setInRange(range)
  })
  return (
    <>
      <Html
        className='sleepy-text'
        position={[0, 0, 1.5]}
        occlude
        onOcclude={setOccluded}
        style={{ transition: 'all 0.2s', opacity: isVisible ? 1 : 0, transform: `scale(${isVisible ? 0.8 : 0.25})` }}
      >
        <div>
          zzZz
        </div>
      </Html>
      <group ref={eyes}>
        <Block position={[-0.3, 0.1, 0.5]} args={[0.2, 0.1, 0.1]} color="black" transparent opacity={0.8} />
        <Block position={[0.3, 0.1, 0.5]} args={[0.2, 0.1, 0.1]} color="black" transparent opacity={0.8} />
      </group>
      <Block ref={mouth} position={[0, -0.2, 0.5]} args={[0.3, 0.05, 0.1]} color="#700000" transparent opacity={0.8} />
    </>
  )
}

export function Guy(props) {
  return (
    <BodyPart name="upperBody" {...props}>
      <BodyPart {...props} name="head" config={joints['neckJoint']} render={<Face />} />
      <BodyPart {...props} name="upperLeftArm" config={joints['leftShoulder']}>
        <BodyPart {...props} name="lowerLeftArm" config={joints['leftElbowJoint']} />
      </BodyPart>
      <BodyPart {...props} name="upperRightArm" config={joints['rightShoulder']}>
        <BodyPart {...props} name="lowerRightArm" config={joints['rightElbowJoint']} />
      </BodyPart>
      <BodyPart {...props} name="pelvis" config={joints['spineJoint']}>
        <BodyPart {...props} name="upperLeftLeg" config={joints['leftHipJoint']}>
          <BodyPart {...props} name="lowerLeftLeg" config={joints['leftKneeJoint']} />
        </BodyPart>
        <BodyPart {...props} name="upperRightLeg" config={joints['rightHipJoint']}>
          <BodyPart {...props} name="lowerRightLeg" config={joints['rightKneeJoint']} />
        </BodyPart>
      </BodyPart>
    </BodyPart>
  )
}
